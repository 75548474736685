<template>
  <data-table v-bind:scope="scope"
              dataTableOptions="favouriteApartmentsGrid"
              queryObj="favouriteObjects"></data-table>
</template>

<script>
import DataTableBase from '@/components/DataTableBase.vue';

export default {
  extends: DataTableBase,
  mounted() {
    this.$store.commit('changeScope', 'portfolio');
    this.$store.commit('changeData', true);
  }
};
</script>